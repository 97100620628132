import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { any, arrayOf, node, object, oneOf, string } from "prop-types";
import { memo } from "react";

import PrizeList from "~modules/Quiz/PrizeList";
import classnames from "~utils/classnames";

import classes from "./DynamicContent.module.scss";

const ArticleOverviewBlock = dynamic(
  () => import("~components/ArticleOverviewBlock"),
);
const FeaturedBlock = dynamic(() => import("~components/FeaturedBlock"));
const IframeEmbed = dynamic(() => import("~components/IframeEmbed"));
const ImageCarousel = dynamic(() => import("~components/ImageCarousel"));
const ImageComponent = dynamic(() => import("~components/ImageComponent"));
const LocationsAndEvents = dynamic(
  () => import("~components/LocationsAndEvents"),
);
const LogosBlock = dynamic(() => import("~components/LogosBlock"));
const Quote = dynamic(() => import("~components/Quote"));
const Partners = dynamic(() => import("~components/sections/Partners"));
const TaggedArticleBlock = dynamic(
  () => import("~components/TaggedArticleBlock"),
);
const TasksBlock = dynamic(() => import("~components/TasksBlock"));
const TopTasksBlock = dynamic(() => import("~components/TopTasksBlock"));
const TrustPilotBlock = dynamic(() => import("~components/TrustPilotBlock"));
const USPBlock = dynamic(() => import("~components/USPBlock"));
const DynamicEventsAndLocationsBlock = dynamic(
  () => import("~modules/DynamicEventsAndLocationsBlock"),
);
const FaqBlock = dynamic(() => import("~modules/FaqBlock"));
const ListWithImageBlock = dynamic(() => import("~modules/ListWithImageBlock"));
const Neighbourhood = dynamic(() => import("~modules/NeighbourhoodBlock"));
const PersonsBlock = dynamic(() => import("~modules/PersonsBlock"));
const ProductGroupBlock = dynamic(() => import("~modules/ProductGroupBlock"));
const RecommendationsBlock = dynamic(
  () => import("~modules/RecommendationsBlock"),
);
const RegistrationForm = dynamic(() => import("~modules/RegistrationForm"));
const RichText = dynamic(() => import("~modules/RichText"));
const YoutubeCarousel = dynamic(() => import("~modules/YoutubeCarousel"));

const CardCarousel = dynamic(() => import("~components/CardCarousel"));
const ExpertCarousel = dynamic(() => import("~modules/ExpertCarousel"));
const YoutubeEmbedBlock = dynamic(() => import("~components/YoutubeAPIEmbed"));
const Countdown = dynamic(() => import("~modules/CountdownBlock"));
const EventsAndLocationsGridBlock = dynamic(
  () => import("~modules/EventsAndLocationsGridBlock"),
);
const CategoryWidget = dynamic(() => import("~modules/CategoryWidget"));

const ContentContainer = ({ children, style, size = "xl" }) => (
  <div
    className={classnames(classes.container, classes[`container_${size}`])}
    style={style}
  >
    <div className={classes.grid}>
      <div className={classes.column}>{children}</div>
    </div>
  </div>
);

/**
 * This is the only instance where a divider should be visible when there is
 * personalized content. This Container is passed to the RecommendationsBlock
 * module so the divider is hidden when there is no personalized content.
 */
// eslint-disable-next-line react/prop-types
const RecommendationsContainer = ({ children }) => (
  <ContentContainer>
    {children}
    <div className={classes.divider} />
  </ContentContainer>
);

ContentContainer.propTypes = {
  children: node,
  style: object,
  size: oneOf(["xs", "sm", "md", "lg", "xl"]),
};

const DynamicContent = ({ content, parentPageId }) => {
  const { locale } = useRouter();

  if (!content || !Array.isArray(content) || content.length === 0) {
    return null;
  }

  return content
    .map((item) => {
      switch (item.type) {
        case "TopTasks":
          return (
            item.topTasks &&
            item.topTasks.length > 0 && (
              <ContentContainer key={item.id}>
                <TopTasksBlock {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "ArticleOverviewBlock":
          return (
            item.articleItems &&
            item.articleItems.length > 0 && (
              <ContentContainer key={item.id}>
                <ArticleOverviewBlock locale={locale} {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "TaggedArticleBlock": {
          const index = content
            .filter(({ type }) => type === "TaggedArticleBlock")
            .indexOf(item);
          let tags = item.tags || [];
          if (tags && typeof tags === "string") {
            // adding "tags" to components in prepr is not possible
            // for now we're using a string input field
            tags = tags.split(",").filter(Boolean);
          }
          return (
            <ContentContainer key={item.id}>
              <TaggedArticleBlock
                {...item}
                index={index}
                locale={locale}
                tags={tags}
                parentId={parentPageId}
              />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        case "FeaturedBlock":
          return (
            item.featuredItems &&
            item.featuredItems.length > 0 && (
              <div key={item.id}>
                <ContentContainer size="md">
                  <FeaturedBlock {...item} />
                </ContentContainer>
                <ContentContainer>
                  <div className={classes.divider} />
                </ContentContainer>
              </div>
            )
          );
        case "UpcomingEventsBlock": {
          // Renamed from UpcomingEventsBlock to DynamicEventsAndLocationsBlock
          let tags = item.tags || [];
          if (tags && typeof tags === "string") {
            // adding "tags" to components in prepr is not possible
            // for now we're using a string input field
            tags = tags
              .split(",")
              .filter(Boolean)
              .map((tag) => tag.trim())
              .filter(Boolean);
          }
          return (
            <ContentContainer key={item.id}>
              <DynamicEventsAndLocationsBlock
                {...item}
                categories={item.categories || []}
                tags={tags}
              />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        case "TasksBlock":
          return (
            item.lists &&
            item.lists.length > 0 && (
              <ContentContainer key={item.id}>
                <TasksBlock {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "YouTube":
          return (
            <ContentContainer key={item.id} size="md" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <YoutubeEmbedBlock embedId={item.embedId} title={item.title} />
                <div className={classes.divider} />
              </div>
            </ContentContainer>
          );
        case "AgendaBlock": {
          // Renamed from AgendaBlock to LocationsAndEvents
          return item.items && item.items.length > 0 ? (
            <ContentContainer key={item.id}>
              <LocationsAndEvents {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          ) : null;
        }
        case "AgendaShortcutWidget": {
          // Renamed from AgendaShortcutWidget to CategoryWidget
          return item.categoryGroups && item.categoryGroups.length > 0 ? (
            <ContentContainer key={item.id}>
              <CategoryWidget title={item.title} items={item.categoryGroups} />
              <div className={classes.divider} />
            </ContentContainer>
          ) : null;
        }
        case "ProductGroupBlock":
          return (
            item.productGroup &&
            item.products &&
            item.products.length > 0 && (
              <div key={`${item.id}-${locale}`}>
                <ProductGroupBlock {...item} />
                <div className={classes.divider} />
              </div>
            )
          );
        case "NeighbourhoodMap":
          return (
            <ContentContainer key={item.id}>
              <Neighbourhood {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Iframe":
          return (
            <ContentContainer key={item.id} size="md" style={{ width: "100%" }}>
              {item.title && (
                <h2 className={classes.item_header}>{item.title}</h2>
              )}
              <IframeEmbed src={item.url} {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "ListWithImage":
          return (
            <ContentContainer key={item.id}>
              <ListWithImageBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Partners":
          return (
            <ContentContainer size="md" key={item.id}>
              <Partners {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Countdown":
          return (
            <ContentContainer key={item.id} size="lg">
              <Countdown {...item} />
            </ContentContainer>
          );
        case "FAQBlock":
          return (
            <ContentContainer key={item.id} size="md">
              <FaqBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "YoutubeCarousel":
          return (
            <ContentContainer key={item.id} size="lg">
              <YoutubeCarousel {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "PersonsBlock":
          return (
            <ContentContainer key={item.id}>
              <PersonsBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "TrustPilotBlock":
          return (
            <ContentContainer key={item.id}>
              <TrustPilotBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "RecommendationsBlock":
          return (
            <RecommendationsBlock
              key={item.id}
              personalization={item.personalization}
              Container={RecommendationsContainer}
            />
          );
        case "IconSlider":
          return (
            <>
              <ExpertCarousel {...item} />
              <div className={classes.spacing} />
            </>
          );
        case "RegistrationForm":
          return (
            <ContentContainer key={item.id} size="md">
              <RegistrationForm {...item} />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "USPSBlock":
          return (
            <ContentContainer key={item.id}>
              <USPBlock
                {...item}
                data={item?.uspBlocks ? [item.uspBlocks[0]] : undefined}
              />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "LogosBlock":
          return (
            <ContentContainer key={item.id}>
              <LogosBlock {...item} />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "Assets": {
          const { images } = item;
          if (!images?.length) {
            return null;
          }
          const key = images.map(({ id }) => id).join("-");
          return (
            <ContentContainer key={key}>
              {images.length === 1 ? (
                <ImageComponent
                  key={images[0].id}
                  {...images[0]}
                  width={1800}
                  height={1020}
                />
              ) : (
                <ImageCarousel key={item.id} images={images} />
              )}
            </ContentContainer>
          );
        }
        case "Text": {
          const { body } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <section>
                <RichText html={body} />
              </section>
            </ContentContainer>
          );
        }
        case "IntroText": {
          const { content } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <section>
                <RichText className={classes.intro} html={content} />
              </section>
            </ContentContainer>
          );
        }
        case "QuoteBlock": {
          const { content, author } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <Quote author={author} className={classes.quote}>
                {content}
              </Quote>
            </ContentContainer>
          );
        }
        case "CardSlider": {
          const { title, items } = item;
          return (
            <ContentContainer key={item.id}>
              <CardCarousel cardType="link" title={title} items={items} />
            </ContentContainer>
          );
        }
        case "EventAndLocationGrid": {
          return (
            <ContentContainer key={item.id}>
              <EventsAndLocationsGridBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        case "QuizPrizeList": {
          return (
            <ContentContainer key={item.id} size="lg">
              <PrizeList {...item} />
            </ContentContainer>
          );
        }
        default:
          return null;
      }
    })
    .filter(Boolean);
};

// this is an exception where we use any so we don't load all the components even if they're not used
// the props will still be validated in the component themselves
DynamicContent.propTypes = {
  content: arrayOf(any),
  parentPageId: string,
};

DynamicContent.displayName = "DynamicContent";

export default memo(DynamicContent);
